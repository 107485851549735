<template>
  <div>
    <div class="box content" >
      <h2>What does travel insurance cover?</h2>

      <div class="columns">
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/cancellation_curtailment_icon.webp" type="image/webp">
                <source srcset="../../assets/images/cancellation_curtailment_icon.png" type="image/png">
                <img src="../../assets/images/cancellation_curtailment_icon.webp" alt="Cancellation Curtailment" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Cancellation or Curtailment</h4>
              <p>
                Cover the expenses of unused and non-refundable travel and
                accommodation costs in case you need to cancel or cut short your
                trip.
              </p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/personal_possessions_icon.webp" type="image/webp">
                <source srcset="../../assets/images/personal_possessions_icon.png" type="image/png">
                <img src="../../assets/images/personal_possessions_icon.webp" alt="Personal Possessions" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Personal Possessions</h4>
              <p>
                Cover if your bags are lost, damaged or stolen, this would be
                covered up to the policy limits.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/medical_expenses_icon.webp" type="image/webp">
                <source srcset="../../assets/images/medical_expenses_icon.png" type="image/png">
                <img src="../../assets/images/medical_expenses_icon.webp" alt="Medical Expenses" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Medical Expenses</h4>
              <p>
                Cover financial expenses should injury, illness or death occur
                during your trip outside the UK.
              </p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/transport_and_flight_delays_icon.webp" type="image/webp">
                <source srcset="../../assets/images/transport_and_flight_delays_icon.png" type="image/png">
                <img src="../../assets/images/transport_and_flight_delays_icon.webp" alt="Transport and Flight Delays" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Transport / Flight Delays</h4>
              <p>Cover the costs of any delays during your trip.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/emergency_assistance_icon.webp" type="image/webp">
                <source srcset="../../assets/images/emergency_assistance_icon.png" type="image/png">
                <img src="../../assets/images/emergency_assistance_icon.webp" alt="Emergency Assistance" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Emergency Assistance</h4>
              <p>
                24/7 assistance should you require medical emergency assistance
                abroad, repatriation or curtailment while on holiday.
              </p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="columns">
            <div class="column is-2">
              <picture>
                <source srcset="../../assets/images/personal_liability_icon.webp" type="image/webp">
                <source srcset="../../assets/images/personal_liability_icon.png" type="image/png">
                <img src="../../assets/images/personal_liability_icon.webp" alt="Personal Liability" loading="lazy" width="80" />
              </picture>
            </div>
            <div class="column is-10">
              <h4 class="mb-1">Personal Liability</h4>
              <p>
                Legal expenses can be provided if you’re held responsible for
                causing damages or injury to another person while on holiday.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="level">
        <div class="level-item">
          <router-link
            tag="button"
            class="button is-primary is-medium hvr-shadow-radial"
            style="width: 250px;"
            :to="{ name: 'getQuote'}"
          >
            GET A QUOTE
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TravelInsuranceCovers',
  data() {
    return {};
  },
  props: ['content'],
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  h1,
  h2,
  h3,
  h4 {
    color: $c2g_blue !important;
  }
  a {
    color: $c2g_blue;
  }
  strong {
    color: $c2g_blue;
  }
  // Making each <li> a tick icon
  li {
    position: relative;
    list-style-type: none;
  }
  li:before {
    content: '';
    position: absolute;
    top: 3px;
    left: -30px;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/images/icons/bullet-tick-sm.png');
  }
  ul {
    margin-top: 5px;
  }
  .card-header-icon {
    color: $c2g_orange;
  }
}
.box {
  margin: 20px 0px 20px 0px;
  padding: 50px;
  background-color: $gray1;
}
.title {
  color: $c2g_blue;
  margin-bottom: 8px;
}
.card-header-title {
  margin-bottom: 0 !important;
}
.card {
  margin-top: 10px;
}
</style>
