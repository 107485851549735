<template>
    <div class="box container is-hidden-touch">
        <div class="logo-containers">
            <h1>Award-Winning Travel Insurance</h1>
            <p>
                Covered2Go offer highly rated, award-winning travel insurance, providing you with complete peace of mind and protection from the moment you book your trip to the moment you arrive home.
            </p>
        </div>
        <div class="logo-containers image-cont">
            <div>
                <picture>
                    <source srcset="/img/defaqto.png" type="image/png">
                    <img src="/img/defaqto.png" loading="lazy" alt="Defaqto Expert rated logo">
                </picture> 
            </div>
            <div>
                <picture>
                    <source srcset="/img/best-travel-insurance-provider-web.png" type="image/png">
                    <img src="/img/best-travel-insurance-provider-web.png" loading="lazy" height="44" alt="Winner of best travel insurance provider logo">
                </picture> 
            </div>
            <div>
                <picture>
                    <source srcset="/img/treating-customers-fairly-web.png" type="image/png">
                    <img src="/img/treating-customers-fairly-web.png" loading="lazy" height="44" alt="Winner of treating customers fairly award logo">
                </picture> 
            </div>

        </div>
    </div>
</template>

<script>
    export default {
      name: 'HomeAwardLogos',
      data() {
        return {};
      },
      mounted() {},
    };
</script>

<style lang="scss" scoped>
    .box {
        margin: 20px 0px 20px 0px;
        padding: 50px;
        background-color: $gray1;
    }

    h1 {
        color: $c2g_blue;
        font-size: 2em;
        margin: 10px;
        font-weight: 600;
        line-height: 1.125;
    }

    p {
        text-align: center;
    }

    .logo-containers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
    }

    .image-cont {
        margin-top: 30px;
    }

    .level {
        margin-bottom: 12px;
    }
</style>