<template>
    <div class="card b-shdw-3">
        <div class="card-image">
            <figure class="image is-4by3v">
                <slot name="image"/>
            </figure>
        </div>
        <div class="card-content">
            <div class="content">
                <slot name="card-content"/>

                <div>
                    <router-link tag="button" class="button is-primary is-medium is-fullwidth hvr-shadow-radial" :to="link">GET A QUOTE</router-link>
                    <router-link tag="button" class="button is-info is-fullwidth hvr-shadow-radial" :to="moreInfoLink">MORE INFO...</router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProductCard",
        props: ['link', 'moreInfoLink']
    }
</script>

<style lang="scss" scoped>
    .card {
        border-radius: 8px;
        margin: 5px auto 10px auto;
        width: 95%;

        // Product card image
        .card-image .image img {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            padding: 7px;
            transition: opacity 0.4s;
            cursor: pointer;
            height: 230px;
            object-fit: cover;
        }
        .card-image .image img:hover {
            opacity: 0.6;
            transition: opacity 0.4s;
        }
        // Card content
        .card-content {
            /*min-height: 320px;*/
            padding-left: 0px;
            padding-right: 0px;
            font-size: 14px;
        }
        button {
            width: 80%;
            display: block;
            margin: 20px auto 10px;
        }
    }
</style>