<template>
    <div class="box">
        <div class="columns">
            <div class="column is-8">
                <div class="content" v-html="content.top_information"></div>
            </div>
            <div class="column is-4">
                <b-notification :closable="false">
                    <b-loading :is-full-page="false" :active="topBlogs.length !== 3"/>
                    <div class="content">
                        <h3>Related articles</h3>
                        <ul>
                             <router-link tag="li" :to="{name:'singleTrip'}">Single Trip Travel Insurance</router-link>
                             <router-link tag="li" :to="{name:'annualTrip'}">Annual Multi-trip Travel Insurance</router-link>
                             <router-link tag="li" :to="{name:'medicalConditions'}">Pre-Existing Medical Conditions Cover</router-link>
                             <router-link tag="li" :to="{name:'winterSportsTravelInsurance'}">Winter Sports Cover</router-link>
                             <router-link tag="li" :to="{name:'cruiseTravelInsurance'}">Cruise Cover</router-link>
                             <router-link tag="li" :to="{name:'travelByDestination'}" >Travel by Destination</router-link>
                             <router-link tag="li" :to="{name:'over65s'}" >Over 65s Travel Insurance</router-link>
                             <router-link tag="li" to="/blog/ghic-information-everything-you-need-to-know" >EHIC / GHIC Information</router-link>
                             <router-link tag="li" :to="{name:'travelInsuranceHelp'}" >Travel Insurance Help</router-link>
                        </ul>

                        <h3>Latest blog posts</h3>
                        <ul>
                            <router-link tag="li" :to="{name:'blogView', params:{'id':b.c.url_name}}" v-for="b in topBlogs" :key="b.id">
                                {{ b.c.title }}
                            </router-link>
                        </ul>
                    </div>
                </b-notification>

            </div>
        </div>

        <div class="level">
            <div class="level-item">
                <router-link
                        tag="button"
                        class="button is-primary is-medium hvr-shadow-radial"
                        style="width: 250px;"
                        :to="{ name: 'getQuote'}"
                >
                    GET A QUOTE
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";

    export default {
        name: "InformationBox",
        data() {
            return {
                blogs: [],
                topBlogs: []
            }
        },
        mixins: [ contentAPI ],
        props: ['content'],
        mounted() {
            // Retrieve blog's
            this.getBlog('*').then((res) => {
                this.blogs = JSON.parse(res.data);
                // Get latest
                let amount = 3;
                for (const [key, value] of Object.entries(this.blogs)) {
                    if(amount > 0) {
                        if(value.status === 'active') {
                            this.topBlogs.push({key: key, c: value});
                            amount--;
                        }
                    } else {
                        break;
                    }
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
    .box {
        margin: 20px 0px 20px 0px;
        padding: 50px;
        background-color: $gray1;
    }
    .content ::v-deep {
        h1, h2, h3, h4 {
            color: $c2g_blue !important;
        }
        ul {
            list-style: none;
            margin-left: 0;
            li {
                margin-left: 25px;
                margin-bottom: 10px;
                position: relative;
                list-style-type: none;
                cursor: pointer;
                border-bottom: 2px solid $gray1;
                transition: 0.6s;
            }
            li:hover {
                border-bottom: 2px solid #0D4F9C;
            }
            li:before {
                content: "";
                position: absolute;
                top: 3px;
                left: -25px;
                width: 20px;
                height: 20px;
                background-image: url('../../assets/images/icons/bullet-tick-sm.png');
            }
        }
    }
</style>