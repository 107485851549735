<template>
  <div class="content">
    <div class="level">
      <div class="level-item" style="padding-top: 1.5rem;">
        <h1>Travel with confidence</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column text-center">
        <div class="box">
          <div>
            <picture>
              <source srcset="../../assets/images/icons/bullet-tick.webp" type="image/webp">
              <source srcset="../../assets/images/icons/bullet-tick.png" type="image/png">
              <img src="../../assets/images/icons/bullet-tick.webp" alt="Tick" loading="lazy" width="150" />
            </picture>
          </div>
          <div class="level">
            <div class="level-item">
              <h2>
                Safe and Secure <br />Online Booking.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="column text-center">
        <div class="box">
          <div>
            <picture>
              <source srcset="../../assets/images/icons/bullet-tick.webp" type="image/webp">
              <source srcset="../../assets/images/icons/bullet-tick.png" type="image/png">
              <img src="../../assets/images/icons/bullet-tick.webp" alt="Tick" loading="lazy" width="150" />
            </picture>
          </div>

          <div class="level">
            <div class="level-item">
              <h2>
                Instant Online<br>
                Policy Delivery
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="column text-center">
        <div class="box">
          <div>
            <picture>
              <source srcset="../../assets/images/icons/bullet-tick.webp" type="image/webp">
              <source srcset="../../assets/images/icons/bullet-tick.png" type="image/png">
              <img src="../../assets/images/icons/bullet-tick.webp" alt="Tick" loading="lazy" width="150" />
            </picture>
          </div>

          <div class="level">
            <div class="level-item">
              <h2>
                Submit A Claim<br>Online Anytime
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeMidPromoBanner',
  data() {
    return {
      blogs: [],
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>

  h1, h2 {
    color: $c2g_blue;
  }

  .box {
    height: 100%;
    background-color: #f6f6f6;
  }

  .tick {
    margin-left: 1.3rem;
  }

.eightdaycover {
  background: lightblue url('../../assets/images/1-card-img.png') no-repeat;
  background-size: 100% 100%;
  height: 400px;
  margin: 5px;
  div {
    margin-top: 30px;
    margin-left: 20px;
    font-size: 25px;
    color: $c2g_blue;
  }
}
.tp {
  background: lightblue url('../../assets/images/2-card-img.png') no-repeat;
  background-size: 100% 100%;
  height: 400px;
  margin: 5px;
  div {
    text-align: center;
    margin-top: 100px;
    margin-left: 0px;
    font-size: 25px;
    color: $c2g_blue;
  }
}
.twonights {
  background: lightblue url('../../assets/images/3-card-img.png') no-repeat;
  background-size: 100% 100%;
  height: 400px;
  margin: 5px;
  div {
    text-align: right;
    margin-top: 30px;
    margin-right: 20px;
    font-size: 25px;
    color: $c2g_blue;
  }
}
.columns {
  margin: 0;
}
.get-a-quote {
  margin-top: 170px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .level-item, .button {
    width: 250px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.review-button {
  margin-top: 10px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .level-item .button {
    width: 300px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

</style>
